import type { EnergyTypes, StandardError, StandardResponse, StandardTypes } from '../types';

interface State {
  energyType: EnergyTypes | null;
  areaId: number | null;
  period: { start: Date | null; end: Date | null };
  standardType: StandardTypes | null;
  standardResponse: StandardResponse | null;
  standardError: StandardError | null;
  loading: boolean;
}

export const standardStore = defineStore('standardStore', {
  state: (): State => ({
    energyType: null,
    areaId: null,
    period: { start: null, end: null },
    standardType: null,
    standardResponse: null,
    standardError: null,
    loading: false,
  }),
  getters: {
    meterId: (state) => {
      const area = areaStore().getArea(state.areaId);
      return area?.meters.find((meter) => meter.meter_type.energy_type === state.energyType)?.id;
    },
  },
  actions: {
    setEnergyType(energyType: State['energyType']) {
      this.energyType = energyType;
    },
    setAreaId(areaId: State['areaId']) {
      this.areaId = areaId;
    },
    setPeriod(period: State['period']) {
      this.period = period;
    },
    setStandardType(standardType: State['standardType']) {
      this.standardType = standardType;
    },
    setLoading(loading: State['loading']) {
      this.loading = loading;
    },
    setStandardResponse(standardResponse: State['standardResponse']) {
      this.standardResponse = standardResponse;
    },
    setStandardError(standardError: State['standardError']) {
      this.standardError = standardError;
    },
  },
});
