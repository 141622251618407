import type { IconName } from '~/components/ui/Icon.vue';

export const useStandardFilter = () => {
  // Composables
  const { t } = useI18n();

  // Store
  const { energyType, areaId } = storeToRefs(standardStore());
  const { setEnergyType } = standardStore();

  // =================================== Filters ===================================
  const getEnergyTypes = computed(() => {
    const area = areaStore().getArea(areaId.value);
    const meters = meterStore().getMeters.reduce((acc, meter) => {
      acc[meter.meter_type.energy_type] = acc[meter.meter_type.energy_type] || [];
      acc[meter.meter_type.energy_type].push(meter);
      return acc;
    }, {});

    return Object.keys(meters).map((energyType) => ({
      label: t(`global.energy_type.${energyType}`),
      key: energyType,
      secondaryLabel: area?.meters.some((meter) => meter.meter_type.energy_type === energyType) ? '' : meters[energyType][0].area.name,
      areaToRedirect: area?.meters.some((meter) => meter.meter_type.energy_type === energyType) ? null : meters[energyType][0].area.id,
    }));
  });

  const getStandardTypes = computed((): { icon: IconName; label: string; key: string }[] => {
    return [
      {
        icon: 'CalendarDays',
        label: t('standard.weekly'),
        key: 'weekly',
      },
      {
        icon: 'CircleX',
        label: t('standard.off_production'),
        key: 'off_production',
      },
      {
        icon: 'Box',
        label: t('standard.production'),
        key: 'production',
      },
      {
        icon: 'FileText',
        label: t('standard.short_fabrication_orders'),
        key: 'fabrication_order',
      },
    ];
  });

  return { energyType, setEnergyType, getEnergyTypes, getStandardTypes };
};
