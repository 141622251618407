<script setup lang="ts">
// Components
import ProductionEventTable from './standardEvent/production.vue';
import OffProductionEventTable from './standardEvent/offProduction.vue';

// Store
const { standardResponse } = storeToRefs(standardStore());
</script>
<template>
  <production-event-table v-if="standardResponse?.type === 'production'" :production-events="standardResponse.items" />
  <off-production-event-table v-else-if="standardResponse?.type === 'off_production'" :production-events="standardResponse.items" />
</template>
