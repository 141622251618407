import type { Header } from '~/components/ui/data/DataTable.vue';

export const useSharedStandardTable = () => {
  const { t } = useI18n();

  // Data

  const headers = computed<Header[]>(() => {
    const base: Header[] = [
      {
        key: 'date',
        label: t('standard.date'),
      },
      {
        key: 'consumption',
        label: t('global.consumption'),
      },
      {
        key: 'power',
        label: t('global.power'),
      },
      {
        key: 'deviation',
        label: t('global.deviation'),
      },
    ];

    return base;
  });

  return { headers };
};
