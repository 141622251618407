<script lang="ts" setup>
import type { ProductionItem } from '~/app-modules/standard/types';

//Composables
const { headers } = useSharedStandardTable();
const { t } = useI18n();
const { formatDate } = useStandardHelper();
const { formatNumberToIsoNumber } = useNumbers();
const { getArea } = areaStore();
const { getEPIOptionListByArea, getConsumptionOptionList, getConsumptionDataList, getEPIDataList } = useGlobalFilter();

// Props
const props = defineProps<{
  productionEvents: ProductionItem[];
}>();

// Store
const { areaId, meterId, loading } = storeToRefs(standardStore());

// Data
const seletedEPIUnit = ref<keyof ReturnType<typeof getEPIDataList>>('quantity');
const seletedConsumptionUnit = ref<keyof ReturnType<typeof getConsumptionDataList>>('quantity');
const seletedDeviationUnit = ref<keyof ReturnType<typeof getConsumptionDataList>>('quantity');

// Computed
const productionHeaders = computed(() => {
  const base = [
    ...headers.value,
    {
      key: 'production',
      label: t('global.production'),
    },
    {
      key: 'epi',
      label: t('global.epi'),
    },
  ];

  if (props.productionEvents.at(0)?.event?.production?.prod_type === 'fabrication_order') {
    base.unshift({
      key: 'fabrication-order-id',
      label: t('standard.fabrication_order_id'),
    });
  }

  return base;
});
</script>

<template>
  <ui-data-table :items="productionEvents" :headers="productionHeaders" :loading="loading">
    <!-- Header -->
    <template #header-epi>
      <div class="flex items-center gap-2">
        <nrjx-input-select
          v-model="seletedEPIUnit"
          name="epi"
          :leading-text="$t('explore.epi')"
          item-key="key"
          item-label="label"
          size="xs"
          :content="{ direction: 'bottom', align: 'end', sideOffset: 4 }"
          :items="getEPIOptionListByArea(areaId, productionEvents?.at(0)?.event?.production?.prod_type)"
        />
      </div>
    </template>
    <template #header-consumption>
      <div class="flex items-center gap-2">
        <nrjx-input-select
          v-model="seletedConsumptionUnit"
          name="consumption"
          :leading-text="$t('global.consumption')"
          item-key="key"
          item-label="label"
          size="xs"
          class="w-[200px]"
          :content="{ direction: 'bottom', align: 'end', sideOffset: 4 }"
          :items="getConsumptionOptionList"
        />
      </div>
    </template>
    <template #header-deviation>
      <div class="flex items-center gap-2">
        <nrjx-input-select
          v-model="seletedDeviationUnit"
          name="deviation"
          :leading-text="$t('global.deviation')"
          item-key="key"
          item-label="label"
          size="xs"
          class="w-[200px]"
          :content="{ direction: 'bottom', align: 'end', sideOffset: 4 }"
          :items="getConsumptionOptionList"
        />
      </div>
    </template>

    <!-- Body -->
    <template #item-fabrication-order-id="{ item }">
      <ui-badge v-if="item.event.production.prod_type === 'fabrication_order'" color="primary">
        {{ item.event.production.client_id }}
      </ui-badge>
    </template>
    <template #item-date="{ item }">
      {{
        formatDate(item.event?.production?.start, item.event?.production?.end, item.event?.production?.prod_type === 'fabrication_order')
      }}
    </template>
    <template #item-consumption="{ item }">
      {{
        getConsumptionDataList({
          consumption: item?.event?.consumption?.quantity,
          cost: Number(item?.event?.consumption?.cost),
          meterId: meterId,
        })[seletedConsumptionUnit]
      }}
    </template>
    <template #item-power="{ item }"> {{ formatNumberToIsoNumber(item.event?.avg_power) }} kW</template>
    <template #item-epi="{ item }">
      {{
        getEPIDataList({
          type: item?.event?.production?.prod_type,
          consumption: item?.event?.consumption_per_unit.quantity,
          cost: Number(item?.event?.consumption_per_unit.cost),
          meterId: meterId,
        })[seletedEPIUnit]
      }}
    </template>
    <template #item-deviation="{ item }">
      <ui-badge v-if="item?.deviation?.consumption?.quantity" :color="item?.deviation?.consumption?.quantity > 0 ? 'red' : 'primary'">
        {{ item?.deviation?.consumption?.quantity > 0 ? '+ ' : '- ' }}
        {{
          getConsumptionDataList({
            consumption: Math.abs(item?.deviation?.consumption?.quantity ?? 0),
            cost: Math.abs(Number(item?.deviation?.consumption?.cost ?? 0)),
            meterId: meterId,
          })[seletedDeviationUnit]
        }}
      </ui-badge>
      <span v-else>--</span>
    </template>
    <template #item-production="{ item }">
      {{ formatNumberToIsoNumber(Number(item.event?.production?.quantity)) }}
      {{ getArea(areaId)?.production_unit?.symbol ?? '--' }}
    </template>
  </ui-data-table>
</template>
