<script setup lang="ts">
import { theme } from '#tailwind-config';
import type { ConsumptionModalProps } from '~/components/modale/consumption.vue';
import dayjs from 'dayjs';

// Store
const { standardResponse, loading, standardType, energyType, standardError, areaId } = storeToRefs(standardStore());

// Composables
const { options } = useStandardChart();

// Data
const open = ref(false);
const modalData = ref<ConsumptionModalProps | null>(null);

const area = computed(() => (areaId.value ? areaStore().getArea(areaId.value) : null));

const getData = computed(() => {
  const response = standardResponse.value;
  if (!response) {
    return [];
  }
  return (
    response.items?.map((item) => {
      switch (response.type) {
        case 'production':
          return Number(item.event.consumption_per_unit.quantity);
        case 'off_production':
          return Number(item.event.avg_power);
        default:
          return 0;
      }
    }) ?? []
  );
});

const labels = computed(() => {
  const response = standardResponse.value;
  if (!response) {
    return [];
  }
  if (response.type === 'production') {
    return response.items?.map((item) => dayjs(item.event.production.start).toDate());
  }
  return response.items?.map((item) => dayjs(item.event.start).toDate());
});

const title = computed(() => {
  switch (standardType.value) {
    case 'weekly':
      return 'kW';
    case 'production':
      return `kWh / ${area.value?.production_unit?.symbol ?? '--'}`;
    case 'fabrication_order':
      return `kWh / ${area.value?.fabrication_order_unit?.symbol ?? '--'}`;
    case 'off_production':
      return 'kW';
    default:
      return '--';
  }
});

// Methods
const openConsumptionModal = (index: number) => {
  const response = standardResponse.value;
  const item = response?.items?.[index];
  if (!item || !areaId.value || !energyType.value) return;

  if (response.type === 'production' && 'production' in item.event) {
    modalData.value = {
      start: item.event.production.start,
      end: item.event.production.end,
      type: item.event.production.prod_type === 'fabrication_order' ? 'fabrication_order' : 'production',
      areaId: areaId.value,
      energyType: energyType.value,
      quantity: Number(item.event.production.quantity),
      consumptionPerUnit: Number(item.event.consumption_per_unit.quantity),
    };
  } else if (response.type === 'off_production' && 'start' in item.event) {
    modalData.value = {
      start: item.event.start,
      end: item.event.end,
      type: response.off_production_type,
      areaId: areaId.value,
      energyType: energyType.value,
      consumptionPerUnit: standardType.value === 'weekly' ? Number(item.event.avg_power) : undefined,
    };
  }

  open.value = true;
};
</script>
<template>
  <nrjx-chart-bar
    :title="title"
    :loading="loading"
    :options="options"
    disabled-zoom
    clickable
    :data="{
      labels: labels,
      datasets: [
        {
          label: standardType,
          data: getData,
          color: theme.colors.primary['500'],
        },
      ],
    }"
    @bar-click="openConsumptionModal($event)"
  >
    <template #no-data>
      <div v-if="standardError" class="flex flex-col items-center justify-center h-full">
        <div class="flex flex-col items-center justify-center border border-orange-500 bg-orange-100 rounded-md p-4">
          <ui-icon name="TriangleAlert" class="text-orange-500 mb-4" />
          <p class="text-orange-500 text-sm">{{ $t(`error.${standardError?.error_code}`) }}</p>
        </div>
      </div>

      <div
        v-if="area?.area_type !== 'machine' && standardType === 'fabrication_order'"
        class="flex flex-col items-center justify-center h-full"
      >
        <div class="flex flex-col items-center justify-center border border-orange-500 bg-orange-100 rounded-md p-4">
          <ui-icon name="TriangleAlert" class="text-orange-500 mb-4" />
          <p class="text-orange-500 text-sm">{{ $t(`error.fabrication_order_only_on_machine_area`) }}</p>
        </div>
      </div>
    </template>
  </nrjx-chart-bar>

  <!-- Modal -->
  <modale-consumption v-model="open" v-bind="modalData" />
</template>
