<script lang="ts" setup>
// Components
import ChartStandard from '../components/charts/standard.vue';
import Filters from '../components/filters/index.vue';
import TableStandard from '../components/tables/standard.vue';

// Composables
const { getKeysFromForage, setKeysInForage } = useStandardStorage();
const { areaId, period, energyType, standardType } = storeToRefs(standardStore());
const { getStandard } = useStandardApi();

// Data
const watcher = ref();

// Lifecycle
onMounted(async () => {
  await getKeysFromForage();

  watcher.value = watch(
    [() => areaId.value, () => period.value, () => energyType.value, () => standardType.value],
    async () => {
      await setKeysInForage();
      await getStandard();
    },
    { deep: true },
  );

  await getStandard();
});

onBeforeUnmount(() => {
  watcher.value();
});
</script>
<template>
  <div class="flex flex-col h-screen overflow-hidden">
    <ui-header :title="$t('standard.standard')" />
    <div class="flex flex-col md:flex-row h-[calc(100vh-64px)] overflow-hidden">
      <filters class="w-full md:w-[320px] flex-shrink-0" />
      <div class="overflow-y-auto p-4 flex-1 flex flex-col gap-4">
        <ui-card>
          <chart-standard />
        </ui-card>
        <ui-card>
          <table-standard />
        </ui-card>
      </div>
    </div>
  </div>
</template>
