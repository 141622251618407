import dayjs from 'dayjs';

export const useStandardApi = () => {
  // Composable
  const { client } = useApiV2();

  // Store
  const { period, energyType, areaId, standardType } = storeToRefs(standardStore());
  const { setLoading } = standardStore();
  const { setStandardResponse, setStandardError } = standardStore();

  // Data
  const abortStandardController = ref<AbortController | null>(null);
  const currentStandardRequestId = ref<string | null>(null);

  // Methods
  // ============ Get Consumption for one area ============
  const getStandard = async () => {
    const requestId = dayjs().toString();
    currentStandardRequestId.value = requestId;

    if (abortStandardController.value) {
      abortStandardController.value.abort();
    }

    try {
      abortStandardController.value = new AbortController();
      setLoading(true);

      const reponse = await client.GET('/v2/areas/{area_id}/standard/details', {
        params: {
          query: {
            energy_type: energyType.value,
            start: dayjs(period.value.start).format('YYYY-MM-DD'),
            end: dayjs(period.value.end).format('YYYY-MM-DD'),
            standard_type: standardType.value,
          },
          path: {
            area_id: areaId.value,
          },
        },
      });

      if (reponse.data.data) {
        setStandardResponse(reponse.data.data);
      } else {
        setStandardError(reponse.data.error);
        setStandardResponse(null);
      }
      setLoading(false);
    } catch (error) {
      if (error.name !== 'AbortError') {
        throw error;
      } else {
        setStandardResponse(null);
      }
    }
  };

  return { getStandard };
};
