<script lang="ts" setup>
import dayjs from 'dayjs';
import type { OffProductionItem } from '~/app-modules/standard/types';

//Composables
const { headers } = useSharedStandardTable();
const { getConsumptionDataList, getConsumptionOptionList } = useGlobalFilter();
const { formatNumberToIsoNumber } = useNumbers();

// Store
const { meterId, loading, standardType } = storeToRefs(standardStore());

// Data
const seletedConsumptionUnit = ref<keyof ReturnType<typeof getConsumptionDataList>>('quantity');
const seletedDeviationUnit = ref<keyof ReturnType<typeof getConsumptionDataList>>('quantity');
defineProps<{
  productionEvents: OffProductionItem[];
}>();
</script>

<template>
  <ui-data-table :items="productionEvents" :headers="headers" :loading="loading">
    <!-- Header -->
    <template #header-consumption>
      <div class="flex items-center gap-2">
        <nrjx-input-select
          v-model="seletedConsumptionUnit"
          name="consumption"
          :leading-text="$t('global.consumption')"
          item-key="key"
          item-label="label"
          size="xs"
          class="w-[200px]"
          :content="{ direction: 'bottom', align: 'end', sideOffset: 4 }"
          :items="getConsumptionOptionList"
        />
      </div>
    </template>
    <template #header-deviation>
      <div class="flex items-center gap-2">
        <nrjx-input-select
          v-model="seletedDeviationUnit"
          name="deviation"
          :leading-text="$t('global.deviation')"
          item-key="key"
          item-label="label"
          size="xs"
          class="w-[200px]"
          :content="{ direction: 'bottom', align: 'end', sideOffset: 4 }"
          :items="getConsumptionOptionList"
        />
      </div>
    </template>

    <!-- Body -->
    <template #item-date="{ item }">
      {{
        dayjs(item.event.start).format(`DD/MM/YYYY ${standardType === 'off_production' ? 'HH:mm' : ''}`) +
        ' - ' +
        dayjs(item.event.end).format(`DD/MM/YYYY ${standardType === 'off_production' ? 'HH:mm' : ''}`)
      }}
    </template>
    <template #item-consumption="{ item }">
      {{
        getConsumptionDataList({
          consumption: item.event.consumption.quantity,
          cost: Number(item.event.consumption.cost),
          meterId: meterId,
        })[seletedConsumptionUnit]
      }}
    </template>
    <template #item-deviation="{ item }">
      <ui-badge v-if="item?.deviation?.consumption?.quantity" :color="item?.deviation?.consumption?.quantity > 0 ? 'red' : 'primary'">
        {{ item?.deviation?.consumption?.quantity > 0 ? '+ ' : '- ' }}
        {{
          getConsumptionDataList({
            consumption: Math.abs(item?.deviation?.consumption?.quantity ?? 0),
            cost: Math.abs(Number(item?.deviation?.consumption?.cost ?? 0)),
            meterId: meterId,
          })[seletedDeviationUnit]
        }}
      </ui-badge>
      <span v-else>--</span>
    </template>
    <template #item-power="{ item }"> {{ formatNumberToIsoNumber(item.event.avg_power) }} kW</template>
  </ui-data-table>
</template>
