<script setup lang="ts">
import { useWindowSize, useElementBounding } from '@vueuse/core';
import * as d3 from 'd3';
import type { TooltipData } from '../../nrjx/chart/tooltip/index.vue';
import dayjs from 'dayjs';
import { theme } from '#tailwind-config';

export interface ChartBarOptions {
  x?: {
    ticks?: {
      callback?: (value: Date) => string;
    };
  };
  tooltip?: (
    data: {
      index: number;
      label: string;
      data: number;
    }[],
  ) => TooltipData;
  annotation?: {
    value: number;
    color: string;
    dashed?: boolean;
  }[];
}

const props = defineProps<{
  title?: string;
  disabledButton?: boolean;
  disabledZoom?: boolean;
  data: {
    labels: Date[];
    datasets: {
      label: string;
      data: number[];
      color: string;
      hover?: boolean;
    }[];
  };
  options?: ChartBarOptions;
  clickable?: boolean;
  isZoomed?: boolean;
  loading?: boolean;
  legend?: boolean;
}>();

const emit = defineEmits<{
  'period-selected': [{ start: Date; end: Date }];
  unzoom: [];
  'bar-click': [number];
}>();

const svgContainer = ref<HTMLDivElement | null>(null);
const tooltipContainer = ref<HTMLDivElement | null>(null);

// D3 specific refs
const svg = shallowRef<d3.Selection<SVGGElement, unknown, null, undefined>>();
const xScale = shallowRef<d3.ScaleBand<string>>();
const yScale = shallowRef<d3.ScaleLinear<number, number>>();
const xAxis = shallowRef<d3.Selection<SVGGElement, unknown, null, undefined>>();
const yAxis = shallowRef<d3.Selection<SVGGElement, unknown, null, undefined>>();
const xAxisHeight = ref(0);

// Add tooltip container ref and position
const tooltipPosition = ref<{ x: number; y: number; display: boolean; arrowOffset?: number; isAbove?: boolean }>();
const tooltipData = ref<TooltipData | null>(null);

// Add a new ref to track initialization
const isInitialized = ref(false);

// Update margin computation to use dynamic bottom margin
const margin = computed(() => ({
  top: 20,
  right: 0,
  bottom: xAxisHeight.value, // Dynamic bottom margin based on x-axis height plus padding
  left: 70,
}));

const { width: containerWidth } = useElementBounding(svgContainer);
const chartWidth = computed(() => {
  const width = containerWidth.value || 600;
  return Math.max(0, width - margin.value.left - margin.value.right);
});
const { height: windowHeight } = useWindowSize();
const chartHeight = computed(() => {
  const totalHeight = windowHeight.value * 0.5;
  return Math.max(0, totalHeight - margin.value.top - margin.value.bottom);
});

// Computed
const checkIfDataIsEmpty = computed(() => {
  if (!props.data?.labels?.length) return true;
  return false;
});

// Initialize scales
const initScales = () => {
  if (!props.data.labels.length) return;

  // Use indices for positioning instead of dates
  const indices = props.data.labels.map((_, index) => index.toString());

  // Use scaleBand for discrete bar positioning with proper padding
  xScale.value = d3.scaleBand().domain(indices).range([0, chartWidth.value]).padding(0.2);

  // Calculer le maximum des données
  const allValues = props.data.datasets.flatMap((dataset) => dataset.data);
  const maxY = Math.max(...allValues, 0);

  if (maxY === 0) {
    // Si toutes les valeurs sont 0, utiliser une échelle de base
    yScale.value = d3.scaleLinear().domain([0, 100]).range([chartHeight.value, 0]).nice();
    return;
  }

  // Calculer l'ordre de grandeur pour arrondir
  const magnitude = Math.pow(10, Math.floor(Math.log10(maxY)));

  // Calculer un intervalle arrondi
  const rawInterval = maxY / 5; // Viser environ 5 graduations
  const roundedInterval = Math.ceil(rawInterval / magnitude) * magnitude;

  // Calculer le maximum arrondi
  const niceMax = Math.ceil(maxY / roundedInterval) * roundedInterval;

  // Créer l'échelle avec le domaine calculé
  yScale.value = d3.scaleLinear().domain([0, niceMax]).range([chartHeight.value, 0]).nice();
};

// Create or update the chart
const updateChart = () => {
  if (!svgContainer.value || !containerWidth.value || checkIfDataIsEmpty.value) {
    return;
  }

  // Clear previous content
  d3.select(svgContainer.value).selectAll('*').remove();

  // If no data, stop here
  if (!props.data?.labels?.length) return;

  // Initialize scales
  initScales();

  // Create SVG with initial dimensions
  const svgElement = d3
    .select(svgContainer.value)
    .append('svg')
    .attr('width', '100%')
    .attr('height', chartHeight.value + margin.value.top + margin.value.bottom)
    .attr(
      'viewBox',
      `0 0 ${chartWidth.value + margin.value.left + margin.value.right} ${chartHeight.value + margin.value.top + margin.value.bottom}`,
    );

  // Create the chart group with proper margin transform
  svg.value = svgElement.append('g').attr('transform', `translate(${margin.value.left},${margin.value.top})`);

  // Add y-axis with grid
  yAxis.value = svg.value
    .append('g')
    .attr('class', 'y-axis')
    .call(
      d3
        .axisLeft(yScale.value!)
        .tickSize(-chartWidth.value)
        .tickFormat((d) => {
          const value = Number(d);
          // Vérifier si le nombre a des décimales significatives
          if (Math.abs(value - Math.round(value)) > 0.01) {
            return d3.format(',.1f')(value); // Un chiffre après la virgule
          }
          return d3.format(',.0f')(value); // Pas de décimales
        })
        .tickPadding(10),
    );

  // Style axes and grid lines
  svg.value.selectAll('.y-axis line').attr('stroke', theme.colors.gray['200']).attr('stroke-opacity', 1);
  svg.value.selectAll('.y-axis path').attr('stroke', 'none');

  // Align y-axis text to the right
  svg.value.selectAll('.y-axis text').style('text-anchor', 'end').attr('dx', '-10');

  // Replace last tick text with title if available
  if (props.title) {
    const ticks = yAxis.value.selectAll('.tick').nodes();
    const lastTick = d3.select(ticks[ticks.length - 1]);
    if (lastTick) {
      lastTick
        .select('text')
        .text(props.title)
        .attr('fill', theme.colors.gray['400'])
        .attr('font-size', '12px')
        .style('text-anchor', 'end')
        .attr('dx', '-10');
    }
  }

  // Add x-axis
  xAxis.value = svg.value.append('g').attr('class', 'x-axis').attr('transform', `translate(0,${chartHeight.value})`);

  // Calculate optimal number of ticks based on minimum spacing
  const minTickSpacing = 45; // 100px for date width + 16px spacing
  const maxTicks = Math.floor(chartWidth.value / minTickSpacing);
  const tickInterval = Math.ceil(props.data.labels.length / maxTicks);

  // Create and style x-axis with calculated ticks
  xAxis.value.call(
    d3
      .axisBottom(xScale.value!)
      .tickSize(0)
      .tickFormat((d) => {
        const index = parseInt(d as string);
        // Only show labels for indices that match the interval
        if (index % tickInterval !== 0) {
          return '';
        }
        const date = props.data.labels[index];
        if (props.options?.x?.ticks?.callback) {
          return props.options.x.ticks.callback(date);
        }
        return dayjs(date).format('YYYY-MM-DD');
      }),
  );

  // Style x-axis labels
  xAxis.value.selectAll('text').attr('transform', 'rotate(-45)').style('text-anchor', 'end').attr('dx', '-0.8em').attr('dy', '0.8em');

  // Calculate X axis height after styling
  calculateXAxisHeight();

  // Add bars
  props.data.datasets.forEach((dataset, datasetIndex) => {
    const barData = dataset.data.map((y, j) => ({
      index: j.toString(),
      y,
      date: props.data.labels[j],
    }));

    const bars = svg
      .value!.append('g')
      .selectAll(`.bar-${datasetIndex}`)
      .data(barData)
      .enter()
      .append('rect')
      .attr('class', `bar-${datasetIndex} ${props.clickable ? 'clickable-bar' : ''}`)
      .attr('x', (d) => {
        const x = xScale.value!(d.index) || 0;
        const barWidth = xScale.value!.bandwidth() / props.data.datasets.length;
        return x + datasetIndex * barWidth;
      })
      .attr('width', xScale.value!.bandwidth() / props.data.datasets.length)
      .attr('y', (d) => {
        const normalY = yScale.value!(d.y);
        const normalHeight = chartHeight.value - normalY;

        // On force une hauteur minimale de 4px pour toutes les barres
        if (normalHeight < 4) {
          return chartHeight.value - 4;
        }
        return normalY;
      })
      .attr('height', (d) => {
        const normalY = yScale.value!(d.y);
        const normalHeight = chartHeight.value - normalY;

        // On force une hauteur minimale de 4px pour toutes les barres
        if (normalHeight < 4) {
          return 4;
        }
        return normalHeight;
      })
      .attr('fill', dataset.color)
      .attr('rx', 2)
      .attr('ry', 2);

    // Add event handlers
    bars
      .on('click', (event, d) => {
        if (props.clickable) {
          event.stopPropagation();
          hideTooltip();
          const index = parseInt(d.index);
          emit('bar-click', index);
        }
      })
      .on('mouseenter', (event, d) => {
        const index = parseInt(d.index);
        showTooltip(event, index);
      })
      .on('mouseleave', hideTooltip);
  });

  // Add annotation lines if they exist
  if (props.options?.annotation?.length) {
    props.options.annotation.forEach((annotation) => {
      // Create the line group
      const lineGroup = svg.value!.append('g').attr('class', 'annotation-line');

      // Add the line
      lineGroup
        .append('line')
        .attr('x1', 0)
        .attr('x2', chartWidth.value)
        .attr('y1', yScale.value!(annotation.value))
        .attr('y2', yScale.value!(annotation.value))
        .attr('stroke', annotation.color)
        .attr('stroke-width', 1)
        .attr('stroke-dasharray', annotation.dashed ? '4,4' : 'none');
    });
  }

  // Handle drag selection
  if (!props.disabledZoom) {
    svg.value
      .append('rect')
      .attr('class', 'chart-overlay')
      .attr('width', chartWidth.value)
      .attr('height', chartHeight.value)
      .attr('fill', 'none')
      .style('pointer-events', 'all')
      .on('mousedown', (event) => {
        const mouseX = d3.pointer(event)[0];
        dragStart.value = mouseX;
        isDragging.value = true;
        hideTooltip();

        svg
          .value!.append('rect')
          .attr('class', 'drag-selection')
          .attr('x', mouseX)
          .attr('y', 0)
          .attr('height', chartHeight.value)
          .attr('width', 0)
          .attr('fill', 'rgba(0, 0, 0, 0.1)');
      });
  }
};

// Create tooltip function
const showTooltip = (event: MouseEvent, index: number) => {
  if (!props.options?.tooltip || !svgContainer.value || !tooltipContainer.value) return;

  const data = props.data.datasets.map((dataset) => ({
    index: index,
    label: dataset.label,
    data: dataset.data[index],
  }));

  // Get tooltip data from callback
  tooltipData.value = props.options?.tooltip(data);

  // Get point coordinates for positioning
  const x = xScale.value!(index.toString())! + xScale.value!.bandwidth() / 2;
  const y = yScale.value!(props.data.datasets[0].data[index]);

  // Get container bounds
  const tooltipRect = tooltipContainer.value.getBoundingClientRect();
  const tooltipHeight = 120; // Approximate height of tooltip
  const tooltipWidth = 200; // Approximate width of tooltip
  const pointX = x + margin.value.left;
  const pointY = y + margin.value.top;

  // Calculate base position
  let posX = pointX;
  let posY = pointY;
  let arrowOffset = 0;
  let isAbove = true;

  // Check if tooltip would go outside top edge
  if (pointY - tooltipHeight - 10 < 0) {
    isAbove = false;
    posY = pointY + 4; // Position below point with smaller gap
  } else {
    posY = pointY - 10; // Position above point
  }

  // Adjust if tooltip would go outside right edge
  if (posX + tooltipWidth / 2 > tooltipRect.width) {
    posX = tooltipRect.width - tooltipWidth / 2;
    arrowOffset = pointX - posX;
  }
  // Adjust if tooltip would go outside left edge
  else if (posX - tooltipWidth / 2 < 0) {
    posX = tooltipWidth / 2;
    arrowOffset = pointX - posX;
  }

  tooltipPosition.value = {
    x: posX,
    y: posY,
    display: true,
    arrowOffset,
    isAbove,
  };
};

const hideTooltip = () => {
  tooltipPosition.value = {
    x: 0,
    y: 0,
    display: false,
  };
  tooltipData.value = null;

  // Remove point indicator when hiding tooltip
  svg.value?.select('.point-indicator').remove();
};

// Add drag selection refs
const dragStart = ref<number | null>(null);
const isDragging = ref(false);

// Add a function to properly initialize the chart
const initializeChart = async () => {
  if (!svgContainer.value || !containerWidth.value || checkIfDataIsEmpty.value || props.loading) {
    return;
  }

  // Wait for next frame to ensure DOM is ready
  await new Promise((resolve) => requestAnimationFrame(resolve));

  // Update the chart (which now handles all dimension calculations internally)
  updateChart();

  isInitialized.value = true;
};

// Add watchers
watch(
  [
    () => props.data,
    () => props.data.labels,
    () => props.data.datasets,
    () => containerWidth.value,
    () => props.loading,
    () => svgContainer.value,
    () => xAxisHeight.value,
    () => props.options,
  ],
  async (_args, _oldArgs) => {
    await initializeChart();
  },
  { deep: true, immediate: true },
);

// Update the onMounted hook
onMounted(async () => {
  if (props.loading || checkIfDataIsEmpty.value) {
    return;
  }

  await initializeChart();
});

onUnmounted(() => {
  if (svgContainer.value) {
    d3.select(svgContainer.value).selectAll('*').remove();
  }
  hideTooltip();
});

// Calculate X axis height
const calculateXAxisHeight = () => {
  if (!xAxis.value) return;
  const height = (xAxis.value.node() as SVGGElement)?.getBBox().height || 0;
  xAxisHeight.value = Math.ceil(height);
};
</script>

<template>
  <div class="relative">
    <div v-if="isZoomed" class="w-full text-gray-400 pl-2 text-xs mb-2 flex items-center justify-between h-[26px]">
      <ui-button color="secondary" size="xs" left-icon="Minimize2" @click="emit('unzoom')">
        {{ $t('global.go_back_to_reference_period') }}
      </ui-button>
    </div>

    <div class="relative flex flex-col justify-end w-full chart-container">
      <div
        v-if="loading"
        class="h-[50vh] z-10 flex flex-col items-center rounded justify-center bg-opacity-50 bg-gray-100 top-0 left-0 right-0 bottom-0"
      >
        <slot name="loading">
          <ui-loader />
          <p class="text-gray-500 text-sm mt-2">{{ $t('global.loading_data') }}</p>
        </slot>
      </div>

      <div
        v-else-if="checkIfDataIsEmpty"
        class="z-10 h-[50vh] flex flex-col items-center rounded justify-center bg-opacity-50 bg-gray-100 top-[36px] left-0 right-0 bottom-0"
      >
        <slot name="no-data">
          <p class="text-gray-500 text-sm">{{ $t('global.no_data') }}</p>
        </slot>
      </div>

      <div v-else ref="svgContainer" class="w-[100%]" />

      <!-- Graph tooltip -->
      <div ref="tooltipContainer" class="absolute transition-transform top-0 left-0 pointer-events-none z-[9999] w-full h-full">
        <nrjx-chart-tooltip
          v-if="tooltipData && tooltipPosition?.display"
          v-bind="tooltipData"
          :is-below="!tooltipPosition.isAbove"
          class="absolute z-[9999] duration-100"
          :class="{
            '-translate-x-1/2 -translate-y-full': tooltipPosition.isAbove,
            '-translate-x-1/2 translate-y-2': !tooltipPosition.isAbove,
          }"
          :style="{
            left: tooltipPosition.x + 'px',
            top: tooltipPosition.y + 'px',
            '--triangle-left': `calc(50% + ${tooltipPosition.arrowOffset || 0}px)`,
            '--triangle-transform': 'translateX(-50%)',
          }"
        />
      </div>
    </div>
  </div>
</template>

<style scoped>
.chart-container {
  overflow: visible;
}

:deep(.line) {
  fill: none;
  stroke-width: 1.5;
}

:deep(.clickable-bar) {
  cursor: pointer;
}

:deep(.x-axis),
:deep(.y-axis) {
  color: #9ca3af;
}

:deep(.x-axis line),
:deep(.y-axis line) {
  stroke: #e5e7eb;
  stroke-width: 1;
  stroke-dasharray: none;
  opacity: 0.5;
}

:deep(.x-axis .domain),
:deep(.y-axis .domain) {
  display: none;
}

:deep(.x-axis text),
:deep(.y-axis text) {
  font-size: 12px;
}

:deep(.nrjx-chart-tooltip) {
  transform-origin: center var(--triangle-position, bottom);
}

.drag-selection {
  pointer-events: none;
  stroke: #666;
  stroke-width: 1px;
}
</style>
