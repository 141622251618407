import localforage from 'localforage';
import type { EnergyTypes, StandardTypes } from '../types';
import dayjs from 'dayjs';

export const useStandardStorage = () => {
  // Stores
  const { energyType, areaId, period, standardType } = storeToRefs(standardStore());
  const { setEnergyType, setAreaId, setPeriod, setStandardType } = standardStore();

  // Data
  const clientId = computed(() => globalStore().getSelectedClient);
  const siteId = computed(() => globalStore().getSelectedSite);

  const getKeysFromForage = async () => {
    const areaForage = await localforage.getItem<number>(`nrjx-${clientId.value}-${siteId.value[0]}-standard-area`);
    const energyTypeForage = await localforage.getItem<EnergyTypes>(`nrjx-${clientId.value}-${siteId.value[0]}-standard-energy-type`);
    const periodForage: { start: Date | null; end: Date | null } = JSON.parse(
      await localforage.getItem<string>(`nrjx-${clientId.value}-${siteId.value[0]}-standard-period`),
    );
    const standardTypeForage = await localforage.getItem<StandardTypes>(`nrjx-${clientId.value}-${siteId.value[0]}-standard-standard-type`);

    // Check if the area is in the forage
    if (areaForage) {
      const area = areaStore().getArea(areaForage);
      area ? setAreaId(areaForage) : setAreaId(areaStore().getAreas[0].id);
    } else {
      setAreaId(areaStore().getAreas[0].id);
    }

    // Check if the standard type is in the forage
    standardTypeForage ? setStandardType(standardTypeForage) : setStandardType('production');

    // Check if the energy type is in the forage and check if the area got this energy type
    if (energyTypeForage) {
      const area = areaStore().getArea(areaId.value);
      if (!area?.meters.find((meter) => meter.meter_type.energy_type === energyTypeForage)) {
        setEnergyType(area?.meters[0].meter_type.energy_type);
      } else {
        setEnergyType(energyTypeForage);
      }
    } else {
      setEnergyType(areaStore().getArea(areaId.value).meters[0].meter_type.energy_type);
    }

    // Check if period is in the forage
    if (periodForage?.start && periodForage?.end) {
      setPeriod(periodForage);
    } else {
      setPeriod({ start: dayjs().startOf('day').subtract(1, 'month').toDate(), end: dayjs().startOf('day').toDate() });
    }
  };

  const setKeysInForage = async () => {
    await localforage.setItem<number>(`nrjx-${clientId.value}-${siteId.value[0]}-standard-area`, areaId.value);
    await localforage.setItem<string>(`nrjx-${clientId.value}-${siteId.value[0]}-standard-energy-type`, energyType.value);
    await localforage.setItem<string>(`nrjx-${clientId.value}-${siteId.value[0]}-standard-period`, JSON.stringify(period.value));
    await localforage.setItem<StandardTypes>(`nrjx-${clientId.value}-${siteId.value[0]}-standard-standard-type`, standardType.value);
  };

  return { getKeysFromForage, setKeysInForage };
};
