import dayjs from 'dayjs';

export const useStandardHelper = () => {
  function formatDate(start: string, end: string, showTime = false) {
    if (dayjs(start).isSame(end, 'minute')) {
      return dayjs(start).format(`DD/MM/YYYY${showTime ? ' HH:mm' : ''}`);
    }
    return (
      dayjs(start).format(`DD/MM/YYYY${showTime ? ' HH:mm' : ''}`) + ' - ' + dayjs(end).format(`DD/MM/YYYY${showTime ? ' HH:mm' : ''}`)
    );
  }

  return {
    formatDate,
  };
};
