import type { StandardKpi } from '../types';

export const useStandardKpi = () => {
  // Store
  const { standardResponse, areaId } = storeToRefs(standardStore());

  // Composables
  const { t } = useI18n();
  const { formatNumberToIsoNumber } = useNumbers();

  // Computed
  const area = computed(() => areaStore().getArea(areaId.value));

  // =================================== KPI ===================================
  const getKpis = computed((): StandardKpi => {
    const data = standardResponse.value;
    if (!data) return null;

    const standardType = data.type;

    if (standardType === 'production') {
      const unit =
        data.items?.at(0)?.event?.production?.prod_type === 'fabrication_order'
          ? (area.value?.fabrication_order_unit?.symbol ?? '--')
          : (area.value?.production_unit?.symbol ?? '--');

      return {
        titleValue: `${formatNumberToIsoNumber(data.average_epi.quantity, 2)}`,
        titleUnit: 'kWh / ' + unit,
        subtitle: t('standard.average_epi'),
        versus: data.average_deviation_percentage
          ? {
              value: formatNumberToIsoNumber(data.average_deviation_percentage ? Math.abs(data.average_deviation_percentage) : 0),
              sign: Math.sign(data.average_deviation_percentage) === 1 ? '+' : '-',
            }
          : null,
        children: [
          {
            icon: 'Gauge',
            kpi: formatNumberToIsoNumber(data.total_consumption.quantity) + ' kWh',
            label: t('standard.total_consumption'),
          },
          {
            icon: 'PlugZap',
            kpi: formatNumberToIsoNumber(data.average_power) + ' kW',
            label: t('standard.average_power'),
          },
          {
            icon: 'ShoppingBag',
            kpi: `${formatNumberToIsoNumber(Number(data.total_production))} ${unit}`,
            label: t('standard.total_quantity'),
          },
        ],
      };
    } else if (standardType === 'off_production') {
      return {
        titleValue: `${formatNumberToIsoNumber(data.average_power)}`,
        titleUnit: 'kW',
        subtitle: t('standard.average_power'),
        versus: data.average_deviation_percentage
          ? {
              value: formatNumberToIsoNumber(data.average_deviation_percentage ? Math.abs(data.average_deviation_percentage) : 0),
              sign: Math.sign(data.average_deviation_percentage) === 1 ? '+' : '-',
            }
          : null,
        children: [
          {
            icon: 'Gauge',
            kpi: formatNumberToIsoNumber(data.total_consumption.quantity) + ' kWh',
            label: t('standard.total_consumption'),
          },
          {
            icon: 'Euro',
            kpi: formatNumberToIsoNumber(Number(data.total_consumption.cost)) + ' €',
            label: t('standard.total_cost'),
          },
        ],
      };
    }

    return null;
  });

  return { getKpis };
};
