import dayjs from 'dayjs';
import type { TooltipData } from '~/components/nrjx/chart/tooltip/index.vue';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import { theme } from '#tailwind-config';
import type { ChartBarOptions } from '~/components/nrjx/chart/bar.vue';

dayjs.extend(weekOfYear);

export const useStandardChart = () => {
  const { standardResponse, standardType } = storeToRefs(standardStore());
  const { areaId } = storeToRefs(standardStore());

  // Composables
  const { t } = useI18n();
  const { formatDate } = useStandardHelper();
  const { formatNumberToIsoNumber } = useNumbers();

  const options = computed<ChartBarOptions>(() => ({
    x: {
      ticks: {
        callback: (value: Date) => {
          if (standardType.value === 'off_production' || standardType.value === 'fabrication_order') {
            return dayjs(value).format('DD/MM/YYYY HH:mm');
          }
          return dayjs(value).format('DD/MM/YYYY');
        },
      },
    },
    annotation: (() => {
      if (standardResponse.value?.standard?.value) {
        return [
          {
            value: Number(standardResponse.value.standard?.value),
            color: theme.colors.green[900],
          },
        ];
      }
      return [];
    })(),
    tooltip: (context) => {
      const response = standardResponse.value;
      if (!response) return null;

      // Production
      if (response?.type === 'production') {
        const data = response?.items[context[0].index];
        const tooltipData: TooltipData = {
          title: `${formatNumberToIsoNumber(data.event.consumption_per_unit.quantity, 2)} kWh / ${areaStore().getArea(areaId.value)?.production_unit?.symbol ?? '--'}`,
          subtitle: (() => {
            if (!data.deviation) return null;
            const value = Math.abs(data.deviation.percentage);
            if (data.deviation.percentage === 0) return null;
            return data.deviation.percentage > 0 ? `+ ${formatNumberToIsoNumber(value, 3)} %` : `- ${formatNumberToIsoNumber(value, 3)} %`;
          })(),
          body: [
            {
              title: t('global.production'),
              value: `${formatNumberToIsoNumber(Number(data.event.production.quantity))} ${areaStore().getArea(areaId.value)?.production_unit?.symbol ?? '--'}`,
            },
            { title: t('global.average_power'), value: `${formatNumberToIsoNumber(Number(data.event.avg_power))} kW` },
          ],
          footer: formatDate(data.event.production.start, data.event.production.end),
        };

        if (data.deviation) {
          tooltipData.body.push({
            title: data.deviation.percentage > 0 ? t('global.drifts') : t('global.win'),
            value: `${formatNumberToIsoNumber(Math.abs(Number(data.deviation.consumption.cost)))} €`,
          });
        }

        return tooltipData;
      }

      // Off production
      if (response?.type === 'off_production') {
        const data = response?.items[context[0].index];
        const tooltipData: TooltipData = {
          title: `${formatNumberToIsoNumber(data.event.avg_power)} kW`,
          subtitle: (() => {
            if (!data.deviation) return null;
            const value = Math.abs(data.deviation.percentage);
            if (data.deviation.percentage === 0) return null;
            return data.deviation.percentage > 0 ? `+ ${formatNumberToIsoNumber(value, 3)} %` : `- ${formatNumberToIsoNumber(value, 3)} %`;
          })(),
          body: [],
          footer: formatDate(data.event.start, data.event.end),
        };

        if (data.deviation) {
          tooltipData.body.push({
            title: data.deviation.percentage > 0 ? t('global.drifts') : t('global.win'),
            value: `${formatNumberToIsoNumber(Math.abs(Number(data.deviation.consumption.cost)))} €`,
          });
        } else {
          tooltipData.body = null;
        }

        return tooltipData;
      }
    },
  }));

  return {
    options,
  };
};
