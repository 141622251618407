<script lang="ts" setup>
import type { EnergyTypes, StandardTypes } from '../../types';

// Composables
const { energyTypeIcon } = useConstants();
const { getEnergyTypes, getStandardTypes } = useStandardFilter();
const { getKpis } = useStandardKpi();
const { formatNumberToIsoNumber } = useNumbers();
const { t } = useI18n();

// Stores
const { energyType, period, areaId, standardType, standardResponse } = storeToRefs(standardStore());
const { setEnergyType, setPeriod, setAreaId, setStandardType } = standardStore();

// Computed
const getEnergyTypeIcon = computed(() => energyTypeIcon(energyType.value));
const getStandardTypeIcon = computed(() => getStandardTypes.value.find((type) => type.key === standardType.value)?.icon);
const getStandardTooltip = computed(() => {
  const standard = standardResponse.value.standard;
  const type = standardResponse.value.type;
  return t('standard.standard_tooltip', {
    standard: formatNumberToIsoNumber(Number(standard.value), 2) + ' ' + (type === 'production' ? 'kWh' : 'kW'),
  });
});

// Methods
const handleEnergyType = async (energyType: EnergyTypes) => {
  if (getEnergyTypes.value.find((type) => type.key === energyType)?.areaToRedirect) {
    setAreaId(getEnergyTypes.value.find((type) => type.key === energyType)?.areaToRedirect);
    setEnergyType(energyType);
  } else {
    setEnergyType(energyType);
  }
};
</script>
<template>
  <div class="flex flex-col bg-white border-r border-gray-200">
    <div class="p-4 grid border-b gap-2 border-gray-200">
      <nrjx-input-select
        name="energy-type"
        :model-value="energyType"
        :leading-icon="getEnergyTypeIcon"
        item-key="key"
        item-label="label"
        fit-to-select
        item-secondary-label="secondaryLabel"
        :items="getEnergyTypes ?? []"
        :disabled="getEnergyTypes?.length < 1"
        :content="{ direction: 'bottom', align: 'start', sideOffset: 4 }"
        @update:model-value="handleEnergyType($event as EnergyTypes)"
      />

      <nrjx-input-select
        name="standard-type"
        :model-value="standardType"
        :leading-icon="getStandardTypeIcon"
        item-key="key"
        item-label="label"
        fit-to-select
        :items="getStandardTypes ?? []"
        :disabled="getStandardTypes?.length < 1"
        :content="{ direction: 'bottom', align: 'start', sideOffset: 4 }"
        @update:model-value="setStandardType($event as StandardTypes)"
      />

      <nrjx-input-date-range-with-shortcut
        name="period"
        leading-icon="Calendar"
        date-format="DD/MM/YYYY"
        type="date"
        :model-value="period"
        :trailing-icon="null"
        @update:model-value="setPeriod($event)"
      />

      <nrjx-input-area
        name="area"
        :area-id="areaId"
        :energy-type="energyType"
        class="w-full"
        side="left"
        @update:area-id="setAreaId($event)"
      />

      <div v-if="getKpis" class="pt-2 gap-2 grid grid-cols-1">
        <div v-if="standardResponse.standard" class="border border-gray-200 bg-gray-50 rounded-md p-3">
          <div class="flex items-center justify-between gap-2">
            <p class="font-semibold text-gray-600">
              {{ formatNumberToIsoNumber(Number(standardResponse.standard.value), 2) }} {{ getKpis?.titleUnit }}
            </p>
            <hover-card-root :open-delay="300" :close-delay="300">
              <hover-card-trigger>
                <ui-icon name="Info" class="w-4 h-4 ml-4 text-gray-600" />
              </hover-card-trigger>
              <hover-card-portal>
                <hover-card-content side="right" :side-offset="24" align="start" class="bg-white p-4 rounded-md shadow w-[300px]">
                  <p class="text-sm text-gray-500" v-html="getStandardTooltip"></p>
                </hover-card-content>
              </hover-card-portal>
            </hover-card-root>
          </div>
          <p class="text-sm text-gray-500">
            {{ $t('standard.standard') }}
          </p>
        </div>
        <div v-if="getKpis.titleValue && getKpis.subtitle" class="border border-gray-200 bg-gray-50 rounded-md p-3">
          <div class="flex items-center gap-2">
            <p class="font-semibold text-gray-600">{{ getKpis?.titleValue }} {{ getKpis?.titleUnit }}</p>
            <ui-badge v-if="getKpis?.versus && getKpis?.versus.value !== '0'" :color="getKpis?.versus.sign === '+' ? 'red' : 'primary'">
              {{ (getKpis?.versus.value !== '0' ? getKpis?.versus.sign + ' ' : '') + (getKpis?.versus.value + ' %') }}
            </ui-badge>
          </div>
          <p class="text-sm text-gray-500">
            {{ getKpis?.subtitle }}
          </p>
        </div>
        <div v-if="getKpis.children" class="grid grid-cols-1 gap-2 pl-4 border-l border-gray-200">
          <div
            v-for="kpi in getKpis?.children"
            :key="kpi.icon"
            class="bg-gray-50 border border-gray-200 h-[32px] w-full rounded-md flex items-center justify-between px-2"
          >
            <div class="flex items-center justify-start gap-2">
              <div class="h-6 w-6 flex items-center justify-center bg-primary-100 rounded-md">
                <ui-icon :name="kpi.icon" class="h-4 stroke-primary-500" />
              </div>
              <p class="text-xs font-semibold text-gray-600">{{ kpi.kpi }}</p>
            </div>
            <p class="text-xs text-gray-500">{{ kpi.label }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
